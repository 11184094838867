export const hellas = {
    players: [
        {
            name: 'Montipò',
            value: 77,
            role: 'P'
        },
        {
            name: 'Tchatchoua',
            value: 67,
            role: 'D'
        },
        {
            name: 'Hien',
            value: 75,
            role: 'D'
        },
        {
            name: 'Dawidowicz',
            value: 73,
            role: 'D'
        },
        {
            name: 'Terracciano',
            value: 69,
            role: 'D'
        },
        {
            name: 'Suslov',
            value: 69,
            role: 'C'
        },
        {
            name: 'Hongla',
            value: 71,
            role: 'C'
        },
        {
            name: 'Ngonge',
            value: 73,
            role: 'C'
        },
        {
            name: 'Folorunsho',
            value: 69,
            role: 'A'
        },
        {
            name: 'Lazovic',
            value: 76,
            role: 'A'
        },
        {
            name: 'Djuric',
            value: 71,
            role: 'A'
        }
    ]
}
