export const juventus = {
    players: [
        {
            name: 'Szczesny',
            value: 86,
            role: 'P'
        },
        {
            name: 'Gatti',
            value: 86,
            role: 'D'
        },
        {
            name: 'Bremer',
            value: 86,
            role: 'D'
        },
        {
            name: 'Danilo',
            value: 88,
            role: 'D'
        },
        {
            name: 'Rabiot',
            value: 91,
            role: 'C'
        },
        {
            name: 'Mckennie',
            value: 88,
            role: 'C'
        },
        {
            name: 'Kostic',
            value: 85,
            role: 'C'
        },
        {
            name: 'Weah',
            value: 82,
            role: 'C'
        },
        {
            name: 'Locatelli',
            value: 85,
            role: 'C'
        },
        {
            name: 'Chiesa',
            value: 95,
            role: 'A'
        },
        {
            name: 'Vlahovic',
            value: 90,
            role: 'A'
        }
    ]
}
