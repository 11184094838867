export const atalanta = {
    players: [
        {
            name: 'Musso',
            value: 79,
            role: 'P'
        },
        {
            name: 'Scalvini',
            value: 75,
            role: 'D'
        },
        {
            name: 'Djmsiti',
            value: 77,
            role: 'D'
        },
        {
            name: 'De Roon',
            value: 80,
            role: 'D'
        },
        {
            name: 'Zappacosta',
            value: 79,
            role: 'C'
        },
        {
            name: 'Ederson',
            value: 73,
            role: 'C'
        },
        {
            name: 'Pasalic',
            value: 75,
            role: 'C'
        },
        {
            name: 'Ruggieri',
            value: 69,
            role: 'C'
        },
        {
            name: 'Koopmeiners',
            value: 69,
            role: 'A'
        },
        {
            name: 'Lookman',
            value: 71,
            role: 'A'
        },
        {
            name: 'Muriel',
            value: 90,
            role: 'A'
        }
    ]
}
