export const fiorentina = {
    players: [
        {
            name: 'Terracciano',
            value: 79,
            role: 'P'
        },
        {
            name: 'Kayode',
            value: 65,
            role: 'D'
        },
        {
            name: 'Milenkovic',
            value: 79,
            role: 'D'
        },
        {
            name: 'Ranieri',
            value: 72,
            role: 'D'
        },
        {
            name: 'Biraghi',
            value: 80,
            role: 'D'
        },
        {
            name: 'Arthur',
            value: 85,
            role: 'C'
        },
        {
            name: 'Duncan',
            value: 74,
            role: 'C'
        },
        {
            name: 'Kouame',
            value: 77,
            role: 'C'
        },
        {
            name: 'Barak',
            value: 78,
            role: 'A'
        },
        {
            name: 'Ikonè',
            value: 77,
            role: 'A'
        },
        {
            name: 'Beltran',
            value: 76,
            role: 'A'
        }
    ]
}
