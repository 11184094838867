export const udinese = {
    players: [
        {
            name: 'Silvestri',
            value: 79,
            role: 'P'
        },
        {
            name: 'Kristensen',
            value: 67,
            role: 'D'
        },
        {
            name: 'Kabasele',
            value: 68,
            role: 'D'
        },
        {
            name: 'Perez',
            value: 76,
            role: 'D'
        },
        {
            name: 'Ebosele',
            value: 71,
            role: 'C'
        },
        {
            name: 'Samadric',
            value: 73,
            role: 'C'
        },
        {
            name: 'Walace',
            value: 75,
            role: 'C'
        },
        {
            name: 'Lovric',
            value: 73,
            role: 'C'
        },
        {
            name: 'Zemura',
            value: 70,
            role: 'C'
        },
        {
            name: 'Pereyra',
            value: 75,
            role: 'A'
        },
        {
            name: 'Lucca',
            value: 72,
            role: 'A'
        }
    ]
}
