export const monza = {
    players: [
        {
            name: 'Di Gregorio',
            value: 79,
            role: 'P'
        },
        {
            name: 'Izzo',
            value: 74,
            role: 'D'
        },
        {
            name: 'Caldirola',
            value: 68,
            role: 'D'
        },
        {
            name: "D'ambrosio",
            value: 77,
            role: 'D'
        },
        {
            name: 'Ciurra',
            value: 79,
            role: 'C'
        },
        {
            name: 'Akpa Akpro',
            value: 73,
            role: 'C'
        },
        {
            name: 'Gagliardini',
            value: 74,
            role: 'C'
        },
        {
            name: 'Kyriakopoulos',
            value: 73,
            role: 'C'
        },
        {
            name: 'Colpani',
            value: 74,
            role: 'A'
        },
        {
            name: 'Pessina',
            value: 78,
            role: 'A'
        },
        {
            name: 'Mota Carvalho',
            value: 80,
            role: 'A'
        }
    ]
}
