export const torino = {
    players: [
        {
            name: 'Milinkovic savic',
            value: 76,
            role: 'P'
        },
        {
            name: 'Tameze',
            value: 74,
            role: 'D'
        },
        {
            name: 'Buongiorno',
            value: 77,
            role: 'D'
        },
        {
            name: 'Rodriguez',
            value: 75,
            role: 'D'
        },
        {
            name: 'Bellanova',
            value: 72,
            role: 'C'
        },
        {
            name: 'Ricci',
            value: 76,
            role: 'C'
        },
        {
            name: 'Ilic',
            value: 76,
            role: 'C'
        },
        {
            name: 'Vojvoda',
            value: 72,
            role: 'C'
        },
        {
            name: 'Vlasic',
            value: 78,
            role: 'A'
        },
        {
            name: 'Sanabria',
            value: 78,
            role: 'A'
        },
        {
            name: 'Zapata',
            value: 80,
            role: 'A'
        }
    ]
}
