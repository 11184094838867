export const genoa = {
    players: [
        {
            name: 'Martinez',
            value: 72,
            role: 'P'
        },
        {
            name: 'Dragusin',
            value: 70,
            role: 'D'
        },
        {
            name: 'Bani',
            value: 71,
            role: 'D'
        },
        {
            name: 'De Winter',
            value: 67,
            role: 'D'
        },
        {
            name: 'Sabelli',
            value: 70,
            role: 'C'
        },
        {
            name: 'Malinovskyi',
            value: 69,
            role: 'C'
        },
        {
            name: 'Badelj',
            value: 72,
            role: 'C'
        },
        {
            name: 'Frendrup',
            value: 72,
            role: 'C'
        },
        {
            name: 'Vasquez',
            value: 70,
            role: 'A'
        },
        {
            name: 'Ekuban',
            value: 71,
            role: 'A'
        },
        {
            name: 'Gudmundsson',
            value: 74,
            role: 'A'
        }
    ]
}
