export const empoli = {
    players: [
        {
            name: 'Berisha',
            value: 70,
            role: 'P'
        },
        {
            name: 'Ebuehi',
            value: 67,
            role: 'D'
        },
        {
            name: 'Ismajli',
            value: 68,
            role: 'D'
        },
        {
            name: 'Luperto',
            value: 67,
            role: 'D'
        },
        {
            name: 'Cacace',
            value: 65,
            role: 'D'
        },
        {
            name: 'Fazzini',
            value: 65,
            role: 'C'
        },
        {
            name: 'Grassi',
            value: 70,
            role: 'C'
        },
        {
            name: 'Maleh',
            value: 65,
            role: 'C'
        },
        {
            name: 'Baldanzi',
            value: 69,
            role: 'A'
        },
        {
            name: 'Cambiaghi',
            value: 75,
            role: 'A'
        },
        {
            name: 'Cancellieri',
            value: 70,
            role: 'A'
        }
    ]
}
