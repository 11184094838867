import React, {useEffect, useState} from 'react';
import './App.css';

function LoadTeam({teams }: any) {

    const [index, setIndex] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            setIndex(index < (teams.length -1) ? index +1 : 0)
        }, 100)
    }, [index]);
  return  <div><img src={'loghi/'+teams[index]?.logo}/></div>
}

export default LoadTeam;
