export const lazio = {
    players: [
        {
            name: 'Provedel',
            value: 82,
            role: 'P'
        },
        {
            name: 'Lazzari',
            value: 79,
            role: 'D'
        },
        {
            name: 'Casale',
            value: 80,
            role: 'D'
        },
        {
            name: 'Patric',
            value: 76,
            role: 'D'
        },
        {
            name: 'Pellegrini',
            value: 76,
            role: 'D'
        },
        {
            name: 'Guendouzi',
            value: 73,
            role: 'C'
        },
        {
            name: 'Luis Alberto',
            value: 90,
            role: 'C'
        },
        {
            name: 'Kamada',
            value: 81,
            role: 'C'
        },
        {
            name: 'Anderson',
            value: 86,
            role: 'A'
        },
        {
            name: 'Immobile',
            value: 85,
            role: 'A'
        },
        {
            name: 'Zaccagni',
            value: 82,
            role: 'A'
        }
    ]
}
