export const roma = {
    players: [
        {
            name: 'Rui Patricio',
            value: 81,
            role: 'P'
        },
        {
            name: 'Mancini',
            value: 83,
            role: 'D'
        },
        {
            name: 'LLorente',
            value: 68,
            role: 'D'
        },
        {
            name: 'Ndicka',
            value: 78,
            role: 'D'
        },
        {
            name: 'Kristensen',
            value: 81,
            role: 'C'
        },
        {
            name: 'Cristante',
            value: 81,
            role: 'C'
        },
        {
            name: 'Paredes',
            value: 80,
            role: 'C'
        },
        {
            name: 'Bove',
            value: 74,
            role: 'C'
        },
        {
            name: 'Pellegrini',
            value: 83,
            role: 'A'
        },
        {
            name: 'Dybala',
            value: 86,
            role: 'A'
        },
        {
            name: 'Lukaku',
            value: 84,
            role: 'A'
        }
    ]
}
