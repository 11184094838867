export const lecce = {
    players: [
        {
            name: 'Falcone',
            value: 76,
            role: 'P'
        },
        {
            name: 'Gendrey',
            value: 67,
            role: 'D'
        },
        {
            name: 'Baschirotto',
            value: 75,
            role: 'D'
        },
        {
            name: 'Touba',
            value: 67,
            role: 'D'
        },
        {
            name: 'Dourgu',
            value: 62,
            role: 'D'
        },
        {
            name: 'Gonzalez',
            value: 70,
            role: 'C'
        },
        {
            name: 'Ramadiani',
            value: 69,
            role: 'C'
        },
        {
            name: 'Rafia',
            value: 67,
            role: 'C'
        },
        {
            name: 'Oudin',
            value: 69,
            role: 'A'
        },
        {
            name: 'Piccoli',
            value: 80,
            role: 'A'
        },
        {
            name: 'Strefezza',
            value: 77,
            role: 'A'
        }
    ]
}
