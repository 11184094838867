export const como = {
    players: [
        {
            name: 'Reina',
            value: 75,
            role: 'P'
        },
        {
            name: 'Cassandro',
            value: 61,
            role: 'D'
        },
        {
            name: 'Goldaniga',
            value: 63,
            role: 'D'
        },
        {
            name: 'Dossena',
            value: 70,
            role: 'D'
        },
        {
            name: 'Moreno',
            value: 64,
            role: 'C'
        },
        {
            name: 'Braunöder',
            value: 66,
            role: 'C'
        },
        {
            name: 'Mazzitelli',
            value: 70,
            role: 'C'
        },
        {
            name: 'Strefezza',
            value: 63,
            role: 'C'
        },
        {
            name: 'Da Cunha',
            value: 72,
            role: 'C'
        },
        {
            name: 'Belotti',
            value: 78,
            role: 'A'
        },
        {
            name: 'Cutrone',
            value: 74,
            role: 'A'
        }
    ]
}
