export const parma = {
    players: [
        {
            name: 'Suzuki',
            value: 66,
            role: 'P'
        },
        {
            name: 'Coulibaly',
            value: 73,
            role: 'D'
        },
        {
            name: 'Balogh',
            value: 71,
            role: 'D'
        },
        {
            name: 'Circati',
            value: 67,
            role: 'D'
        },
        {
            name: 'Valeri',
            value: 71,
            role: 'D'
        },
        {
            name: 'Estèvez',
            value: 73,
            role: 'C'
        },
        {
            name: 'Cyprien',
            value: 71,
            role: 'C'
        },
        {
            name: 'Man',
            value: 73,
            role: 'C'
        },
        {
            name: 'Bernabè',
            value: 79,
            role: 'A'
        },
        {
            name: 'Almqvist',
            value: 71,
            role: 'A'
        },
        {
            name: 'Mihaila',
            value: 72,
            role: 'A'
        }
    ]
}
