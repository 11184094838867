import {atalanta} from "./teams/atalanta";
import {bologna} from "./teams/bologna";
import {cagliari} from "./teams/cagliari";
import {empoli} from "./teams/empoli";
import {fiorentina} from "./teams/fiorentina";
import {como} from "./teams/como";
import {genoa} from "./teams/genoa";
import {hellas} from "./teams/hellas";
import {inter} from "./teams/inter";
import {juventus} from "./teams/juventus";
import {lazio} from "./teams/lazio";
import {lecce} from "./teams/lecce";
import {milan} from "./teams/milan";
import {monza} from "./teams/monza";
import {napoli} from "./teams/napoli";
import {roma} from "./teams/roma";
import {parma} from "./teams/parma";
import {venezia} from "./teams/venezia";
import {torino} from "./teams/torino";
import {udinese} from "./teams/udinese";

export const teamsData = [
    {
        name: 'Atalanta',
        logo: 'atalanta.webp',
        ...atalanta
    },
    {
        name: 'Bologna',
        logo: 'bologna.webp',
        ...bologna
    },
    {
        name: 'Cagliari',
        logo: 'cagliari.webp',
        ...cagliari
    },
    {
        name: 'Como',
        logo: 'como.webp',
        ...como
    },
    {
        name: 'Empoli',
        logo: 'empoli.webp',
        ...empoli
    },
    {
        name: 'Fiorentina',
        logo: 'fiorentina.webp',
        ...fiorentina
    },
    {
        name: 'Genoa',
        logo: 'genoa.webp',
        ...genoa
    },
    {
        name: 'Hellas Verona',
        logo: 'verona.webp',
        ...hellas
    },
    {
        name: 'Inter',
        logo: 'inter.webp',
        ...inter
    },
    {
        name: 'Juventus',
        logo: 'juventus.webp',
        ...juventus
    },
    {
        name: 'Lazio',
        logo: 'lazio.webp',
        ...lazio
    },
    {
        name: 'Lecce',
        logo: 'lecce.webp',
        ...lecce
    },
    {
        name: 'Milan',
        logo: 'milan.webp',
        ...milan
    },
    {
        name: 'Monza',
        logo: 'monza.webp',
        ...monza
    },
    {
        name: 'Napoli',
        logo: 'napoli.webp',
        ...napoli
    },
    {
        name: 'Parma',
        logo: 'parma.webp',
        ...parma
    },
    {
        name: 'Roma',
        logo: 'roma.webp',
        ...roma
    },
    {
        name: 'Torino',
        logo: 'torino.webp',
        ...torino
    },
    {
        name: 'Udinese',
        logo: 'udinese.webp',
        ...udinese
    },
    {
        name: 'Venezia',
        logo: 'venezia.webp',
        ...venezia
    },
].map((ele, index) => ({...ele, index: [index]}))
