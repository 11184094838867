import React, {useState} from 'react';
import './App.css';
import Match from "./Match";
import {teamsData} from "./data/teamsData";

function App() {
  const getArrayByNumber = (n: number, increment: number = 0) => {
    return Array(n).fill('').map((_, i) => i+increment);
  }
  const array = getArrayByNumber(20);
  const [extraction, setExtraction] = useState<boolean>(false);
  const [firstTeam, setFirstTeam] = useState<any>();
  const [secondTeam, setSecondTeam] = useState<any>();
  const [otherTeamsData, setOtherTeamsData] = useState<any>();
  const [teams, setTeams] = useState(teamsData)
  const setMatchHandler = () => {
    setExtraction(true);
    setTimeout(() => {
      const random = Math.round(Math.random()*(teams.length-1))
      const firstT = teams[random]
      setFirstTeam(firstT)
      const fields = array.reduce((acc:{fields: any[], num: number}, ele: number) => {
        if(acc.num <3){
          return {
            fields: acc.fields.map((el, index) => index === (acc.fields.length -1)? [...el, ele] : el),
            num: acc.num +1
          }
        } else {
          return {
            fields: [...acc.fields, [ele]],
            num: 0
          }
        }
      }, {fields: [], num:3 })
      const findTeam = (squares: any[], indexFirst: number, indexSecond: number, acc: any[], teamsFilter: any[]) => {
        const field = squares[indexFirst][indexSecond];
        const team = teams.find(ele => ele.index.includes(field))
        if(!acc.find(t => t.name === team?.name)){
          teamsFilter = [...teamsFilter, team]
        }
        return (!acc.find(t => t.name === team?.name))? [...teamsFilter, team] : teamsFilter
      }
      const teamsCalc = firstT.index.reduce((acc: any[], ele: number) => {
        const indexFields = fields.fields.findIndex(el => el.includes(ele));
        const indexArrayField = fields.fields[indexFields].findIndex((el: number) => el === ele);
        let teamsFilter: any[] = [];
        if(indexArrayField -1 >= 0){
          teamsFilter = findTeam(fields.fields, indexFields, indexArrayField -1, acc, teamsFilter)
        }

        if(indexArrayField +1 < fields.fields[indexFields].length){
          teamsFilter = findTeam(fields.fields, indexFields, indexArrayField +1, acc, teamsFilter)
        }
        if((indexFields-1) >= 0){
          if(indexArrayField -1 >= 0){
            teamsFilter = findTeam(fields.fields, indexFields-1, indexArrayField -1, acc, teamsFilter)
          }
          teamsFilter = findTeam(fields.fields, indexFields-1, indexArrayField, acc, teamsFilter)
          if(indexArrayField +1 < fields.fields[indexFields-1].length){
            teamsFilter = findTeam(fields.fields, indexFields-1, indexArrayField +1, acc, teamsFilter)
          }
        }
        if((indexFields+1) < fields.fields.length){
          if(indexArrayField -1 >= 0){
            teamsFilter = findTeam(fields.fields, indexFields+1, indexArrayField -1, acc, teamsFilter)
          }
          teamsFilter = findTeam(fields.fields, indexFields+1, indexArrayField, acc, teamsFilter)
          if(indexArrayField +1 < fields.fields[indexFields+1].length){
            teamsFilter = findTeam(fields.fields, indexFields+1, indexArrayField +1, acc, teamsFilter)
          }
        }

        return [...acc, ...teamsFilter]
      }, [])
      const otherTeams = teamsCalc.filter(ele => ele.name !== firstT.name)
      setOtherTeamsData(otherTeams)
      setTimeout(() => {
        const randomSecond =Math.round(Math.random()*(otherTeams.length -1))
        const secondT = otherTeams[randomSecond]
        setSecondTeam(secondT)
      }, 3000)
    }, 3000)
  }
  const setEndGame = (win: any, loser: any) => {
    setTeams(teams.map(ele => ele.name === win.name? {...ele, index: [...ele.index, ...loser.index]} : ele).filter(ele => ele.name !== loser.name))
    setOtherTeamsData(undefined)
    setFirstTeam(undefined)
    setSecondTeam(undefined)
    setExtraction(false)
  }
  return (<div className="container-fluid">
        <button className="btn btn-info" onClick={setMatchHandler}>Prossimo scontro</button>
        {extraction ? <Match otherTeams={otherTeamsData || teams} firstTeam={firstTeam} secondTeam={secondTeam} setEndGame={setEndGame}  />  :  <div className="row text-center">
          {array.map(field => {
                const team: any = teams.find(ele => ele.index.includes(field))
                return <div className="col-3">
                  <div>{team.name}</div>
                  <div><img style={{maxWidth: '160px'}} src={'loghi/'+team.logo}/></div>
                </div>
              }
          )
          }
        </div>}

  </div>

  );
}

export default App;
