export const cagliari = {
    players: [
        {
            name: 'Scuffet',
            value: 75,
            role: 'P'
        },
        {
            name: 'Nandez',
            value: 67,
            role: 'D'
        },
        {
            name: 'Goldaniga',
            value: 75,
            role: 'D'
        },
        {
            name: 'Dossena',
            value: 75,
            role: 'D'
        },
        {
            name: 'Augello',
            value: 65,
            role: 'D'
        },
        {
            name: 'Sulemana',
            value: 70,
            role: 'C'
        },
        {
            name: 'Prati',
            value: 71,
            role: 'C'
        },
        {
            name: 'Jankto',
            value: 80,
            role: 'C'
        },
        {
            name: 'Viola',
            value: 69,
            role: 'A'
        },
        {
            name: 'Luvumbo',
            value: 71,
            role: 'A'
        },
        {
            name: 'Pavoletti',
            value: 80,
            role: 'A'
        }
    ]
}
