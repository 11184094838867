export const napoli = {
    players: [
        {
            name: 'Meret',
            value: 83,
            role: 'P'
        },
        {
            name: 'Di Lorenzo',
            value: 85,
            role: 'D'
        },
        {
            name: 'Rrahmani',
            value: 81,
            role: 'D'
        },
        {
            name: 'Juan Jesus',
            value: 75,
            role: 'D'
        },
        {
            name: 'Mario Rui',
            value: 81,
            role: 'D'
        },
        {
            name: 'Anguissa',
            value: 83,
            role: 'C'
        },
        {
            name: 'Lobotka',
            value: 84,
            role: 'C'
        },
        {
            name: 'Zielinski',
            value: 83,
            role: 'C'
        },
        {
            name: 'Raspadori',
            value: 79,
            role: 'A'
        },
        {
            name: 'Osimhen',
            value: 88,
            role: 'A'
        },
        {
            name: 'Kvaratskhelia',
            value: 86,
            role: 'A'
        }
    ]
}
