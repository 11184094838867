export const inter = {
    players: [
        {
            name: 'Sommer',
            value: 84,
            role: 'P'
        },
        {
            name: 'Di Marco',
            value: 82,
            role: 'D'
        },
        {
            name: 'Acerbi',
            value: 83,
            role: 'D'
        },
        {
            name: 'Bastoni',
            value: 85,
            role: 'D'
        },
        {
            name: 'Darmian',
            value: 80,
            role: 'C'
        },
        {
            name: 'Barella',
            value: 86,
            role: 'C'
        },
        {
            name: 'Calhanoglu',
            value: 85,
            role: 'C'
        },
        {
            name: 'Mkhitaryan',
            value: 81,
            role: 'C'
        },
        {
            name: 'Dumfries',
            value: 81,
            role: 'A'
        },
        {
            name: 'Thuram',
            value: 79,
            role: 'A'
        },
        {
            name: 'Lautaro',
            value: 87,
            role: 'A'
        }
    ]
}
