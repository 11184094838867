import React, {useState} from 'react';
import './App.css';
import LoadTeam from "./LoadTeam";
import SimulateMatch from "./SimulateMatch";

function Match({firstTeam, secondTeam, setEndGame, otherTeams }: any) {
    const [simulateMatch, setSimulateMatch] = useState<boolean>(false)
    const [result, setResult] = useState<any>()
    const setSimulateMatchHandler = () => {
        setSimulateMatch(true)
    }
  return (<div className="row">
          <div className="col-6 text-center">
            <div>In casa</div>
            <div>{firstTeam?.name || 'caricamento in corso'}</div>
            {firstTeam? <div onClick={() => setEndGame(firstTeam, secondTeam)}><img src={'loghi/'+firstTeam?.logo}/></div> : <LoadTeam teams={otherTeams} />}


          </div>
          {firstTeam && <div className="col-6  text-center">
              <div>trasferta</div>
              <div>{secondTeam?.name || 'caricamento in corso'}</div>
              {secondTeam ?
                  <div onClick={() => setEndGame(secondTeam, firstTeam)}><img src={'loghi/' + secondTeam?.logo}/>
                  </div> : <LoadTeam teams={otherTeams}/>}


          </div>}
          {!!firstTeam && !!secondTeam && !result && <button onClick={setSimulateMatchHandler}>Gioca</button>}
          {simulateMatch && <SimulateMatch firstTeam={firstTeam} secondTeam={secondTeam} setResult={setResult} />}
          {!!result && <button onClick={() => setEndGame(result.win, result.lose)}>Termina</button>}
          <div className="col-6 text-center">
              {firstTeam && <div>
                  <div>Totale valore squadra: {Math.round(firstTeam.players.reduce((acc: number, ele: any) => acc + ele.value, 0)/11)}</div>
                  {firstTeam.players.map((player: any) => <div>
                      {player.name} ({player.role})
                  </div>)}
              </div>}

          </div>
          {firstTeam && <div className="col-6  text-center">
              {secondTeam && <div>
                  <div>Totale valore squadra: {Math.round(secondTeam.players.reduce((acc: number, ele: any) => acc + ele.value, 0)/11)}</div>
                  {secondTeam.players.map((player: any) => <div>
                      {player.name} ({player.role})
                  </div>)}
              </div>}

          </div>}
        </div>

  );
}

export default Match;
