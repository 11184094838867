import React, {useEffect, useState} from 'react';

function SimulateMatch({firstTeam, secondTeam, setResult }: any) {
    const [goals, setGoals] = useState<any[]>([])
    const [minute, setMinute] = useState<number>(0)
    const [endGame, setEndGame] = useState<boolean>(false)
    useEffect(() => {
        if(endGame){
            const golTeam1 = goals.filter(ele => ele.team === 1)
            const golTeam2 = goals.filter(ele => ele.team === 2)
            setResult({
                win: golTeam2 > golTeam1? secondTeam : firstTeam,
                lose: golTeam2 < golTeam1? secondTeam : firstTeam,
            })
        }

    }, [endGame]);
    useEffect(() => {
            if(minute === 90){
                const golTeam1 = goals.filter(ele => ele.team === 1)
                const golTeam2 = goals.filter(ele => ele.team === 2)
                if(golTeam1.length === golTeam2.length){
                    const team = Math.round(Math.random()*2)
                    const randomPlayer = Math.round(Math.random()*10)
                    const player = (team === 1? firstTeam : secondTeam).players[randomPlayer]
                    setGoals([...goals, {
                        player,
                        minute,
                        team
                    }])
                }
                setEndGame(true)
            } else {
                setTimeout(() => {
                    const defenceTeam1List = firstTeam.players.filter((ele: any) => ['D', 'P'].includes(ele.role))
                    const defenceTeam1 = defenceTeam1List.reduce((acc: number, ele: any) => acc + ele.value, 0)/defenceTeam1List.length
                    const midfieldTeam1List = firstTeam.players.filter((ele: any) => ['C'].includes(ele.role))
                    const midfieldTeam1 = midfieldTeam1List.reduce((acc: number, ele: any) => acc + ele.value, 0)/midfieldTeam1List.length
                    const attackTeam1List = firstTeam.players.filter((ele: any) => ['A'].includes(ele.role))
                    const attackTeam1 = attackTeam1List.reduce((acc: number, ele: any) => acc + ele.value, 0)/attackTeam1List.length
                    const defenceTeam2List = secondTeam.players.filter((ele: any) => ['D', 'P'].includes(ele.role))
                    const defenceTeam2 = defenceTeam2List.reduce((acc: number, ele: any) => acc + ele.value, 0)/defenceTeam2List.length
                    const midfieldTeam2List = secondTeam.players.filter((ele: any) => ['C'].includes(ele.role))
                    const midfieldTeam2 = midfieldTeam2List.reduce((acc: number, ele: any) => acc + ele.value, 0)/midfieldTeam2List.length
                    const attackTeam2List = secondTeam.players.filter((ele: any) => ['A'].includes(ele.role))
                    const attackTeam2 = attackTeam2List.reduce((acc: number, ele: any) => acc + ele.value, 0)/attackTeam2List.length
                    const randomDiff = Math.round(Math.random()*300)
                    const randomAttack = Math.round(Math.random()*25)
                    const teamAttack = Math.round(Math.random()*2)
                    if(teamAttack === 1){
                        const defence = defenceTeam2 + (midfieldTeam2/2) + randomDiff
                        const attack = attackTeam1 + (midfieldTeam1/2) + randomAttack
                        if(attack > defence){
                            const playersWithBonus = firstTeam.players.map((ele: any) => {
                                const addAttack = ele.role === 'A' ? Math.round(Math.random()*50) : 0;
                                const addMidfield = ele.role === 'C' ? Math.round(Math.random()*25) : 0;
                                const addSpecial = Math.round(Math.random()*25);
                                return {
                                    ...ele, value: ele.value + addAttack + addMidfield + addSpecial
                                }
                            })
                            const player = playersWithBonus.reduce((acc: any, t: any) => acc && acc.value > t.value ? acc : t , undefined)
                            setGoals([...goals, {
                                player,
                                minute,
                                team: 1
                            }])
                        }
                    }else{
                        const defence = defenceTeam1 + (midfieldTeam1/2) + randomDiff
                        const attack = attackTeam2 + (midfieldTeam2/2) + randomAttack
                        if(attack > defence){
                            const playersWithBonus = secondTeam.players.map((ele: any) => {
                                const addAttack = ele.role === 'A' ? Math.round(Math.random()*50) : 0;
                                const addMidfield = ele.role === 'C' ? Math.round(Math.random()*25) : 0;
                                const addSpecial = Math.round(Math.random()*25);
                                return {
                                    ...ele, value: ele.value + addAttack + addMidfield + addSpecial
                                }
                            })
                            const player = playersWithBonus.reduce((acc: any, t: any) => acc && acc.value > t.value ? acc : t , undefined)
                            setGoals([...goals, {
                                player,
                                minute,
                                team: 2
                            }])
                        }
                    }

                    setMinute(minute +1)
                }, 500)

            }
    }, [minute]);

    return <div>
        <div className="text-center">{minute}'</div>
        <div className="row" style={{fontSize: '25px'}}>
            <div className="col-6 text-center">{goals.filter(ele => ele.team === 1).length}</div>
            <div className="col-6 text-center">{goals.filter(ele => ele.team === 2).length}</div>
        </div>
        <div className="row">
            <div className="col-6">{goals.filter(ele => ele.team === 1).map(ele => <div><img src="goal_icon.svg" />{ele.minute}' {ele?.player?.name}</div>)}</div>
            <div className="col-6">{goals.filter(ele => ele.team === 2).map(ele => <div><img src="goal_icon.svg" />{ele.minute}' {ele?.player?.name}</div>)}</div>
        </div>
    </div>
}

export default SimulateMatch;
