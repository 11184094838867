export const bologna = {
    players: [
        {
            name: 'Skorupski',
            value: 77,
            role: 'P'
        },
        {
            name: 'Posh',
            value: 77,
            role: 'D'
        },
        {
            name: 'Beukema',
            value: 68,
            role: 'D'
        },
        {
            name: 'Calafiori',
            value: 67,
            role: 'D'
        },
        {
            name: 'Kristiansen',
            value: 70,
            role: 'D'
        },
        {
            name: 'Ferguson',
            value: 73,
            role: 'C'
        },
        {
            name: 'Freuler',
            value: 75,
            role: 'C'
        },
        {
            name: 'Moro',
            value: 73,
            role: 'C'
        },
        {
            name: 'Urbanski',
            value: 69,
            role: 'A'
        },
        {
            name: 'Zirkee',
            value: 71,
            role: 'A'
        },
        {
            name: 'Saelemaekers',
            value: 70,
            role: 'A'
        }
    ]
}
