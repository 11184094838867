export const milan = {
    players: [
        {
            name: 'Maignan',
            value: 87,
            role: 'P'
        },
        {
            name: 'Calabria',
            value: 81,
            role: 'D'
        },
        {
            name: 'Kjaer',
            value: 81,
            role: 'D'
        },
        {
            name: 'Tomori',
            value: 84,
            role: 'D'
        },
        {
            name: 'Hernandez',
            value: 85,
            role: 'D'
        },
        {
            name: 'Loftus-Cheek',
            value: 77,
            role: 'C'
        },
        {
            name: 'Bennacer',
            value: 84,
            role: 'C'
        },
        {
            name: 'Reijnders',
            value: 78,
            role: 'C'
        },
        {
            name: 'Pulisic',
            value: 79,
            role: 'A'
        },
        {
            name: 'Giroud',
            value: 82,
            role: 'A'
        },
        {
            name: 'Leao',
            value: 86,
            role: 'A'
        }
    ]
}
