export const venezia = {
    players: [
        {
            name: 'Joronen',
            value: 79,
            role: 'P'
        },
        {
            name: 'Altare',
            value: 72,
            role: 'D'
        },
        {
            name: 'Sverko',
            value: 74,
            role: 'D'
        },
        {
            name: 'Svoboda',
            value: 72,
            role: 'D'
        },
        {
            name: 'Zampano',
            value: 67,
            role: 'D'
        },
        {
            name: 'Kofod',
            value: 72,
            role: 'C'
        },
        {
            name: 'Duncan',
            value: 75,
            role: 'C'
        },
        {
            name: 'Sagrado',
            value: 73,
            role: 'C'
        },
        {
            name: 'Pierini',
            value: 70,
            role: 'A'
        },
        {
            name: 'Oristanio',
            value: 70,
            role: 'A'
        },
        {
            name: 'Gytkjaer',
            value: 72,
            role: 'A'
        }
    ]
}
